import React from 'react';
import banmap from '../assets/images/img_final/page-title-bg.jpg';
import mapa from '../assets/images/img_final/mapa.png';

const Maps = () => {
    const backmap = {
        backgroundImage:`url(${banmap})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'center',
    }
    return (
        <>
            <section className="py-3" style={backmap} id="ubicaciones">
                <div className="container pt-5">
                    <div className="col-xl-12 px-0 text-center py-5">
                        <h2 className="pb-0 mb-0 text-dark">Presencia Nacional</h2>
                        <p className="blue-line-king">—</p>
                    </div>
                </div>
                <div className="row mx-0">
                    <div className="col-xl-8">
                        <div className="col-xl-12 text-center p-5">
                            <img className="img-fluid" src={mapa} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-4 px-5">
                        <div className="row mx-0 text-white">
                            <div className="col-12 col-md-6 col-xl-12 py-2">
                                <div className="col-xl-12 pt-4">
                                    <div className="col-xl-12 px-4 pt-2 bg-blueking shadow zoom location-box">
                                        <h5>Oficinas propias:</h5>
                                        <ul className="size-list">
                                            <li>Matamoros</li>
                                            <li>NuevoLaredo/Colombia</li> 
                                            <li>Veracruz</li>
                                            <li>CDMX</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12 py-2">
                                <div className="col-xl-12 pt-4">
                                    <div className="col-xl-12 px-4 pt-2 bg-warning shadow zoom location-box">
                                        <h5>Alianzas:</h5>
                                        <ul className="size-list">
                                            <li>Manzanillo</li>
                                            <li>Altamira</li>
                                            <li>Coatzacoalcos</li>
                                            <li>AICM</li>
                                            <li>Querétaro</li>
                                            <li>San Luis Potosí</li>
                                            <li>Chihuahua</li>
                                            <li>Cd. Juárez</li>
                                            <li>Toluca</li>
                                            <li>Progreso</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Maps