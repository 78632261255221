import React from 'react'
import img_almacen from '../assets/images/img_final/storage.jpeg';
import texas from '../assets/images/img_final/texas.png';
import usa from '../assets/images/img_final/usa.png';
import city from '../assets/images/img_final/city.png';
import cbp from '../assets/images/img_final/cbp.png';

const Valor = () => {
    const iconsBlue = {
        position:'relative',
        top:'-20px',
        // left:'0%',
    }
    const fontBox = {
        fontSize:'20px',
    }
    const centerText={
        paddingTop:'10%',
        paddingLeft:'10%',
    }
    return (
        <>
            <section className="py-3" >
                <div className="container pt-5">
                    <div className="col-xl-12 px-0 text-center py-5">
                        <h2 className="pb-0 mb-0 text-dark">Valor Agregado Bodegas</h2>
                        <p className="blue-line-king">—</p>
                    </div>
                </div>
                <div className="row mx-0 bg-blueking">
                    <div className="col-xl-6 text-white p-4">
                        <div style={centerText}>
                            <ul  style={fontBox}>
                                <li>Circuito cerrado de vigilancia.</li>
                                <li>Carga y descarga de mercancía.</li>
                                <li>Consolidación y desconsolidación de carga.</li>
                                <li>Tramitación de documentos aduanales americanos.</li>
                                <li>Equipo, personal e instalaciones especializados.</li>
                                <li>Área especializada para químicos y materiales peligrosos.</li>
                                <li>Área especializada en alimentos .</li>
                                <li>Área para mercancía IN –BOND.</li>
                            </ul> 
                        </div>
                    </div>
                    <div className="col-xl-6 px-0">
                        <div className="col-xl-12 px-0">
                            <img className="img-fluid" src={img_almacen}  alt="" />
                        </div>
                    </div>
                </div>
                <div className="row mx-0" id="certificados">
                    <div className="col-xl-12 py-5">
                        <div className="container pt-5">
                            <div className="col-xl-12 px-0 text-center py-5">
                                <h2 className="pb-0 mb-0 text-dark">Certificados</h2>
                                <p className="blue-line-king">—</p>
                            </div>
                        </div>
                        <div className="row py-3 mx-0">
                            <div className="col-md-6 col-lg-6 col-xl-3 py-2">
                                <div className="col-xl-12 p-4 all-border-blue">
                                    <div className="text-center " style={iconsBlue}>
                                        <img src={texas} alt="" />
                                    </div>
                                    <p>Certificación para el manejo de alimentos, emitida por el Estado de Texas por el Departamento de Servicios a la Salud.</p>   
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-3 py-2">
                                <div className="col-xl-12 p-4 all-border-blue">
                                    <div className="text-center circle_icon">
                                        <img src={usa} alt="" />
                                    </div>
                                    <p>Certificación para el manejo de Materiales Peligroso emitido por Departamento de Transporte (DOT) de USA.</p>   
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-3 py-2">
                                <div className="col-xl-12 p-4 all-border-blue">
                                    <div className="text-center circle_icon">
                                        <img src={city} alt="" />
                                    </div>
                                    <p>Permiso para el Manejo de Materiales Peligrosos emitido por el Departamento de Servicios Ambientales de la Ciudad de Laredo.</p>   
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-3 py-2">
                                <div className="col-xl-12 p-4 all-border-blue">
                                    <div className="text-center circle_icon">
                                        <img src={cbp} alt="" />
                                    </div>
                                    <p>Autorización para almacenaje de mercancía IN-BOND por la CBP</p>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Valor