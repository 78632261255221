import React from "react"
import Navbarnew from "../new-components/nav-bar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../new-components/banner"
import SecondBanner from "../new-components/secondBanner"
import Products from "../new-components/products"
import Almacen from "../new-components/almacen"
import Valor from "../new-components/valor"
import Map from "../new-components/map"
import Diferencia from "../new-components/diferencias"
const Home = () => {
  return (
    <Layout>
      <Navbarnew/>
      <Banner/>
      <SecondBanner/>
      <Products/>
      <Almacen/>
      <Valor/>
      <Map/>
      <Diferencia/> 
      <Footer />
    </Layout>
  )
}

export default Home