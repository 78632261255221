import { Link } from 'gatsby';
import React from 'react';

import BlackBan from '../assets/images/img_final/img_compress/black.webp';
const Diferencia = () => {
    const backblack = {
        backgroundImage:`url(${BlackBan})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'center',
    }
    return (
        <>
            <section className="py-3" style={backblack}>
                <div className="container pt-5">
                    <div className="col-xl-12 px-0 text-center py-5">
                        <h2 className="pb-0 mb-0 text-white">Diferenciadores Competitivos</h2>
                        <p className="blue-line-king">—</p>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 text-white">
                            <div className="col-xl-12 py-2">
                                <div className="col-xl-12 border-bicolor px-2">
                                    <p><span className="text-warning">Infraestructura</span> informática propia </p>
                                </div>
                            </div>
                            <div className="col-xl-12 py-2">
                                <div className="col-xl-12 border-bicolor px-2">
                                    <p><span className="text-warning">Digitalización</span> de expedientes en cada operación </p>
                                </div>
                            </div>
                            <div className="col-xl-12 py-2">
                                <div className="col-xl-12 border-bicolor px-2">
                                    <p><span className="text-warning">Facturación ágil</span> (3er día a partir del cruce en frontera)</p>
                                </div>
                            </div>
                            <div className="col-xl-12 py-2">
                                <div className="col-xl-12 border-bicolor px-2">
                                    <p><span className="text-warning">Procedimientos</span> de control para embarques</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 text-white">
                            <div className="col-xl-12 py-2">
                                <div className="col-xl-12 border-bicolor px-2">
                                    <p><span className="text-warning">Revisiones previas</span> (Confirmación de fracciones arancelarias)</p>
                                </div>
                            </div>
                            <div className="col-xl-12 py-2">
                                <div className="col-xl-12 border-bicolor px-2">
                                    <p><span className="text-warning">Interpretación y aplicación</span> de requisitos ante la OISA </p>
                                </div>
                            </div>
                            <div className="col-xl-12 py-2">
                                <div className="col-xl-12 border-bicolor px-2">
                                    <p>Tramites de <span className="text-warning">certificados de exportación ante la USDA</span> ( APHIS) </p>
                                </div>
                            </div>
                            <div className="col-xl-12 py-2">
                                <div className="col-xl-12 border-bicolor px-2">
                                    <p><span className="text-warning">Sistema automatizado</span>  para el control de operaciones </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="col-xl-12">
                                <div className="col-xl-12 bg-warning px-4 text-center">
                                    <h5>Nivel mínimo de errores y discrepancia en nuestra operación, ningún PAMA en nuestras oficinas.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 text-center py-5">
                            <div className="col-xl-12">
                                <Link to="/contact" className="btn text-white btn-white">Contactenos Ahora</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Diferencia