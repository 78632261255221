import React from 'react';

import almacene from "../assets/images/img_final/img_compress/almacen.webp";


const Almacen = () => {
    const parallax = {
        backgroundImage:`url(${almacene})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'center',
        backgroundAttachment: 'fixed',
        minHeight:'450px',
    }
    return (
        <>
            <section className="py-3" style={parallax} id="almacen">
                <div className="container py-5">
                    <div className="col-xl-12 px-0 text-center py-5">
                        <h2 className="pb-0 mb-0 text-white">Almacenaje & CEDIS</h2>
                        <p className="blue-line-king">—</p>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 px-5 py-5">
                            <div className="col-xl-12 bg-blueking">
                                <div className="col-xl-12 text-center py-5">
                                    <h4 className="text-white">Laredo, Texas</h4>
                                    <h6 className="text-warning">USA</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 px-5 py-5">
                            <div className="col-xl-12 bg-blueking">
                                <div className="col-xl-12 text-center py-5">
                                    <h4 className="text-white">Brownsville, Texas</h4>
                                    <h6 className="text-warning">IN-BOND READY</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Almacen