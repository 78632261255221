import React from "react"
import banfrist from "../assets/images/img_final/img_compress/ban_bar.webp";

const Banner = () => {
    const back01 = {
        backgroundImage:`url(${banfrist})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'center',
    }
    return (
        <>
            <section className="py-5" style={back01}>
                <div className="container py-5">
                    <div className="col-xl-12 px-0 text-center py-5">
                        <div className="col-xl-12 py-5">
                            <h1 className="text-white pt-5">Servicios Aduanales y Logísticos S.C</h1>
                            <h5 className="text-warning">Porque su tranquilidad es nuestra responsabilidad</h5>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner