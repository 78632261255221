import React from 'react'
import bansecond from "../assets/images/img_final/img_compress/bg-nosotros.webp";
import we_image01 from "../assets/images/img_final/img_compress/nosotros-img1.webp";
import we_image02 from "../assets/images/img_final/img_compress/nosotros-img2.webp";
import we_image03 from "../assets/images/img_final/img_compress/nosotros-img3.webp";

const SecondBanner = () => {
    const back02 = {
        backgroundImage:`url(${bansecond})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'center',
    }
    return(
        <>
            <section className="py-3" style={back02} id="nosotros">
                <div className="container py-5">
                    <div className="col-xl-12 px-0 text-center py-5">
                        <h2 className="pb-0 mb-0">Nosotros</h2>
                        <p className="blue-line-king">—</p>
                        <h6 className="text-dark">Empresa perteneciente al grupo AA J. KING , agencia aduanal con más de 30 años de experiencia y liderazgo.</h6>
                    </div>
                    <div className="row mx-0">
                        <div className="col-md-4 col-lg-4 col-xl-4 py-1">
                            <div className="col-xl-12 zoom">
                                <img className="img-fluid" src={we_image01} alt="" />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 py-1">
                            <div className="col-xl-12 zoom">
                                <img className="img-fluid" src={we_image02} alt="" />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 py-1">
                            <div className="col-xl-12 zoom">
                                <img className="img-fluid" src={we_image03} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 px-0 text-center pt-5">
                        <h6 className="text-white">IWB Logistics junto con la agencia AA KING brinda atención profesional durante el proceso de comercio exterior a través de los servicios de asesoría, transporte y despacho aduanal.</h6>
                        <p className="bg-warning">Nuestra garantía esta basada en el control total de toda su operación; ya que somos freightforward y agencia aduanal.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SecondBanner