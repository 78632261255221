import React from "react"

import banproduct from "../assets/images/img_final/page-title-bg.jpg";
import img_product from "../assets/images/img_final/bg-productos.jpeg";
import avion from "../assets/images/img_final/avion.png";
import barco from "../assets/images/img_final/barco-con-contenedores.png";
import caja from "../assets/images/img_final/caja.png";
import certificado from "../assets/images/img_final/certificado.png";

const Products = () => {
    const back03 = {
        backgroundImage:`url(${banproduct})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'center',
    }
    return (
        <>
            <section className="py-3" style={back03} id="productos">
                <div className="container py-5">
                    <div className="col-xl-12 px-0 text-center py-5">
                        <h2 className="pb-0 mb-0">Productos</h2>
                        <p className="blue-line-king">—</p>
                    </div>
                    <div className="row mx-0">
                        <div className="col-xl-5">
                            <div className="col-xl-12">
                                <img className="img-fluid" src={img_product} alt="" />
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="row mx-0">
                                <div className="col-xl-6 p-4">
                                    <div className="col-xl-12 p-3 shadow box-log zoom">
                                            <h6 className="text-blueking">
                                                <img src={avion} width="20" height="20" alt="" /> Despacho Aduanal
                                            </h6>
                                            <ul className="size-list">
                                                <li>Importación</li>
                                                <li>Exportación</li>
                                            </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 p-4">
                                    <div className="col-xl-12 p-3 shadow box-log zoom">
                                        <h6 className="text-blueking">
                                            <img src={barco} width="25" height="25" alt="" /> Transportación Multimodal</h6>
                                            <ul className="size-list">
                                                <li>Aérea</li>
                                                <li>Maritima</li>
                                                <li>Terrestre</li>
                                            </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 p-4">
                                    <div className="col-xl-12 p-3 shadow box-log zoom">
                                        <h6 className="text-blueking">
                                            <img src={caja} width="20" height="20" alt="" /> Operación Logística</h6>
                                            <ul className="size-list">
                                                <li> Servicio Door to Door</li>
                                                <li> Carga suelta y consolidada</li>
                                                <li> Almacenaje</li>
                                                <li> Consultoria en comercio Exterior</li>
                                                <li> Manejo de mercancía de grado alimenticio</li>
                                                <li> HAZ-MAT</li>
                                            </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 p-4">
                                    <div className="col-xl-12 p-3 shadow box-log zoom">
                                        <h6 className="text-blueking">
                                            <img src={certificado} width="20" height="20" alt="" /> Seguro de Mercancías</h6>
                                            <ul className="size-list">
                                                <li> Cobertura Nacional o Internacional</li>
                                                <li> Avería gruesa y particular</li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}


export default Products